const titleList = document.getElementsByClassName('glance__title')
const imageList = document.getElementsByClassName('glance__image-wrapper')

if (titleList.length > 1) {
    const titles = Array.from(titleList)
    const images = Array.from(imageList)
    titles.map((title, key) => {
        title.addEventListener('mouseenter', (event) => {
            titles.map((t, i) => {
                t.classList.remove('active')
                images[i].classList.remove('active')
            })
            title.classList.add('active')
            images[key].classList.add('active')
        })
        title.addEventListener('click', (event) => {
            titles.map((t, i) => {
                t.classList.remove('active')
                images[i].classList.remove('active')
            })
            title.classList.add('active')
            images[key].classList.add('active')
        })
        title.parentNode.addEventListener('focus', (event) => {
            titles.map((t, i) => {
                t.classList.remove('active')
                images[i].classList.remove('active')
            })
            title.classList.add('active')
            images[key].classList.add('active')
        })
    })
}
