const wordList = document.getElementsByClassName('header-homepage__word')
let index = 0

if (wordList.length > 0) {
    const words = Array.from(wordList)
    words.map(w => {
        const word = w.innerHTML
        let newHtml = ''
        for (let i = 0; i < word.length; i++)
            newHtml = newHtml + '<span class="header-homepage__char">' + word.charAt(i) + '</span>'

        w.innerHTML = newHtml
    })

    const updateIndex = () => {
        for (let i = words.length - 1; i >= 0; i--)
            if (i === index) {
                words[i].classList.remove('active')

                if (index === words.length - 1)
                    index = 0
                else
                    index = index + 1

                Array.from(words[index].getElementsByClassName('header-homepage__char')).map(c => {
                    c.style.opacity = 0
                })

                words[index].classList.add('active')
                words.map(w => {
                    const chars = Array.from(w.getElementsByClassName('header-homepage__char'))
                    for (let i = 0; i < chars.length; i++)
                        setTimeout(function () {
                            chars[i].style.opacity = 1
                        }, 50 * i)
                })
                break
            }
    }

    window.setInterval(updateIndex, 5000)
}

