const VHChromeFix = function (selectors) {
    const self = this
    const userAgent = window.navigator.userAgent.toLowerCase()
    const isSafariMobile = /safari/.test(userAgent)
        && /mobile/.test(userAgent) && !/chrome/.test(userAgent)
    const isAndroidChrome = /chrome/.test(userAgent)
        && /android/.test(userAgent)
    const isIOSChrome = /crios/.test(userAgent)

    if (isAndroidChrome || isIOSChrome || isSafariMobile) {
        // If we detected Chrome on Android or iOS
        // Cache elements and trigger fix on init
        this.getElements(selectors)
        this.fixAll()

        // Cache window dimensions
        this.windowWidth = window.innerWidth
        this.windowHeight = window.innerHeight

        window.addEventListener('resize', function () {
            // Both width and height changed (orientation change)
            // This is a hack, as Android when eyboard pops up
            // Triggers orientation change
            if (self.windowWidth !== window.innerWidth
                    && self.windowHeight !== window.innerHeight) {
                self.windowWidth = window.innerWidth
                self.windowHeight = window.innerHeight
                self.fixAll()
            }
        })
    }
}

VHChromeFix.prototype.getElements = function (selectors) {
    this.elements = []
    // Convert selectors to array if they are not
    selectors = this.isArray(selectors) ? selectors : [selectors]

    for (var i = 0; i < selectors.length; i++) {
    // Get all elements for selector
        var selector = selectors[i].selector
        var elements = document.querySelectorAll(selector)

        // Go through all elements for one selector to filter them
        for (var j = 0; j < elements.length; j++)
            this.elements.push({
                domElement: elements[j],
                vh: selectors[i].vh
            })
    }
}

VHChromeFix.prototype.isArray = function (array) {
    return Object.prototype.toString.call(array) === '[object Array]'
}

VHChromeFix.prototype.fixAll = function () {
    for (var i = 0; i < this.elements.length; i++) {
        var element = this.elements[i]

        element.domElement.style.height = (window.innerHeight * element.vh / 100 - 80) + 'px'
    }
}

var vhFix = new VHChromeFix([{
    selector: '#homepage__fixed-height',
    vh: 100
}])

console.log(vhFix)
