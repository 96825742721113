// import smoothscroll from 'smoothscroll-polyfill'
//smoothscroll.polyfill()

const arrowNodesE = document.getElementsByClassName('upcoming-events__arrow')
const backNodesE = document.getElementsByClassName('upcoming-events__arrow-back')
const eventNodes = document.getElementsByClassName('upcoming-events__list')

// require('smoothscroll-polyfill').polyfill()
// // import smoothscroll from 'smoothscroll-polyfill';
// //smoothscroll.polyfill();

let scrolledE = 0

if (arrowNodesE.length > 0) {
    const arrowsE = Array.from(arrowNodesE)
    const backsE = Array.from(backNodesE)
    const events = Array.from(eventNodes)

    arrowsE.map((arrow, key) => {
        arrow.addEventListener('click', () => {
            if (events[key].scrollLeft + events[key].offsetWidth < events[key].scrollWidth) {
                scrolledE += 261
            }

            events[key].scroll({
                top: 0,
                left: scrolledE,
                behavior: 'smooth'
            })
        })

        arrow.addEventListener('keypress', (e) => {
            if (e.charCode === 13) {
                if (events[key].scrollLeft + events[key].offsetWidth < events[key].scrollWidth) {
                    scrolledE += 261
                }

                events[key].scroll({
                    top: 0,
                    left: scrolledE,
                    behavior: 'smooth'
                })
            }
        })
    })

    backsE.map((arrow, key) => {
        arrow.addEventListener('click', () => {
            if (scrolledE > 0) {
                scrolledE -= 261
            }

            events[key].scroll({
                top: 0,
                left: scrolledE,
                behavior: 'smooth'
            })
        })

        arrow.addEventListener('keypress', (e) => {
            if (e.charCode === 13) {
                if (scrolledE > 0) {
                    scrolledE -= 261
                }

                events[key].scroll({
                    top: 0,
                    left: scrolledE,
                    behavior: 'smooth'
                })
            }
        })
    })
}
