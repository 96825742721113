(($) => {
    const homePeopleMod = document.querySelector('.people-module__wrapper')

    if (!homePeopleMod)
        return

    $(document).ready(function () {
        $('.people-module__wrapper')
            .on([
                'webkitTransitionEnd',
                'otransitionend',
                'oTransitionEnd',
                'msTransitionEnd',
                'transitionend'
            ].join(' '), function (event) {
                $(this).attr('data-transition', 'false')
            })

        var $owl = $('.carousel').owlCarousel({
            items: 1,
            animateOut: 'fadeOut',
            animateIn: 'fadeIn',
            mouseDrag: false,
            touchDrag: false,
            pullDrag: false,
            smartSpeed: 10,
            slideSpeed: 10,
            autoHeight: true,
            loop: false,
            onTranslated: function (event) {
                $('.owl-item').removeClass('slide-animate')
                $('.owl-item.active').addClass('slide-animate')
                $('.owl-item').removeClass('owl-animated-out')
                $('.owl-item').removeClass('fadeOut')
                $('.owl-item').removeClass('animated')
            },
            onInitialized: function (data) {
                $('.home__slide').parent().addClass('slide-animate')
            }
        })

        function owlFix () {
            if ($(window).innerWidth() < 961) {
                $('.carousel').data('owl.carousel').options.animateOut = 'fadeOut'
                $('.carousel').data('owl.carousel').options.animateIn = 'fadeIn'
                $('.carousel').trigger('refresh.owl.carousel')
            }
            else {
                $('.carousel').data('owl.carousel').options.animateOut = ''
                $('.carousel').data('owl.carousel').options.animateIn = ''
                $('.carousel').trigger('refresh.owl.carousel')
            }
        }

        $(window).resize(function () {
            owlFix()
        })

        owlFix()

        $('.home__slide .person__badge').click(function () {
            $('.people-module__wrapper').attr('data-bg-fixed', 'true')
        })

        $('.person__badge-home').hover(function () {
            var topZ = 0

            $(this).parent().parent().find('.person__badge-home')
                .each(function () {
                    var thisZ = parseInt($(this).css('z-index'), 10)
                    if (thisZ > topZ)
                        topZ = thisZ
                })

            $(this).css('z-index', topZ + 1)
        })

        $('.person__badge').hover(
            function () {
                var topZ = 0
                $(this).parent().find('.person__badge').each(function () {
                    var thisZ = parseInt($(this).css('z-index'), 10)

                    if (thisZ > topZ)
                        topZ = thisZ
                })

                $(this).css('z-index', topZ + 1)
            })

        $('.person__slide .person__badge').hover(
            function () {
                $(this).children('.badge__hover-label').show()
            }, function () {
                $(this).children('.badge__hover-label').hide()
            })

        $('.home__slide .person__badge').hover(
            function () {
                $(this).children('.badge__hover-label').show()

                var bgClass = $(this).data('wrapper-bg')

                if (!$('.people-module__wrapper').hasClass(bgClass)
                        && $(this).parent().parent().parent().parent().parent()
                            .hasClass('slide-animate')) {
                    $('.people-module__wrapper')
                        .removeClass(function (index, className) {
                            return (className
                                .match(/(^|\s)person__badge-wrapper-bg-\S+/g) || [])
                                .join(' ')
                        })

                    $('.people-module__wrapper').addClass(bgClass)
                }
            }, function () {
                if ($('.people-module__wrapper').attr('data-bg-fixed') !== 'true') {
                    var bgClass = $(this).data('wrapper-bg')
                    $(this).children('.badge__hover-label').hide()
                    $('.people-module__wrapper').removeClass(bgClass)
                }
            })

        $('.people-module__title').click(function () {
            if (!$('.home__slide').parent().hasClass('active')) {
                $('.owl-item').removeClass('slide-animate')
                $('.home__slide').css('display', 'flex')
                $('.badge__hover-label').hide()

                if ($(window).innerWidth() > 960)
                    setTimeout(function () {
                        $owl.trigger('to.owl.carousel', 0)

                        $('.people-module__wrapper').attr('data-bg-fixed', '')
                        $('.people-module__wrapper')
                            .removeClass(function (index, className) {
                                return (className
                                    .match(/(^|\s)person__badge-wrapper-bg-\S+/g) || [])
                                    .join(' ')
                            })

                        $('.home__slide').parent().addClass('slide-animate')
                    }, 400)
                else {
                    $owl.trigger('to.owl.carousel', 0)
                    $('.people-module__wrapper').attr('data-bg-fixed', '')
                    $('.people-module__wrapper').removeClass(function (index, className) {
                        return (className
                            .match(/(^|\s)person__badge-wrapper-bg-\S+/g) || [])
                            .join(' ')
                    })

                    $('.home__slide').parent().addClass('slide-animate')
                }
            }
        })

        $('.person__back-button .back-button__arrow').click(function () {
            if (!$('.home__slide').parent().hasClass('active')) {
                $('.owl-item').removeClass('slide-animate')
                $('.home__slide').css('display', 'flex')
                $('.badge__hover-label').hide()

                if ($(window).innerWidth() > 960)
                    setTimeout(function () {
                        $owl.trigger('to.owl.carousel', 0)

                        $('.people-module__wrapper').attr('data-bg-fixed', '')
                        $('.people-module__wrapper')
                            .removeClass(function (index, className) {
                                return (className
                                    .match(/(^|\s)person__badge-wrapper-bg-\S+/g) || [])
                                    .join(' ')
                            })

                        $('.home__slide').parent().addClass('slide-animate')
                    }, 400)
                else {
                    $owl.trigger('to.owl.carousel', 0)

                    $('.people-module__wrapper').attr('data-bg-fixed', '')
                    $('.people-module__wrapper')
                        .removeClass(function (index, className) {
                            return (className
                                .match(/(^|\s)person__badge-wrapper-bg-\S+/g) || [])
                                .join(' ')
                        })
                    $('.home__slide').parent().addClass('slide-animate')
                }

                if ($(window).innerWidth() < 961)
                    $('html, body').animate({
                        scrollTop: ($('.people-module__wrapper').offset().top - 50)
                    }, 500, 'linear')
            }
        })

        $('.group__container .badge__image, .grouping .badge__image')
            .click(function () {
                $(this).parent().parent().find('.badge__hover-label').hide()
                $('.owl-item').removeClass('slide-animate')

                var id = $(this).parent().data('id')
                var index = $('.person__slide[data-id="' + id + '"]')
                    .data('index')

                if ($(window).innerWidth() > 960)
                    setTimeout(function () {
                        $owl.trigger('to.owl.carousel', index)

                        $('.person__slide[data-id="' + id + '"]').parent()
                            .addClass('slide-animate')
                    }, 400)
                else {
                    $owl.trigger('to.owl.carousel', index)
                    $('.person__slide[data-id="' + id + '"]').parent()
                        .addClass('slide-animate')
                }

                if ($(window).innerWidth() < 961)
                    $('html, body').animate({
                        scrollTop: ($('.people-module__wrapper').offset().top - 50)
                    }, 500, 'linear')
            })

        $('.group__container .badge__image').click(function () {
            if ($(window).innerWidth() < 961)
                $('html, body').animate({
                    scrollTop: ($('.people-module__wrapper').offset().top - 50)
                }, 500, 'linear')
        })

        $('.person__slide .person__group .person__badge, .person__slide .group__container .person__badge').click(function () {
            var bgClass = $(this).data('wrapper-bg')

            $('.people-module__wrapper')
                .removeClass(function (index, className) {
                    return (className
                        .match(/(^|\s)person__badge-wrapper-bg-\S+/g) || [])
                        .join(' ')
                })
            $('.people-module__wrapper').addClass(bgClass)
        })
    })
})(window.jQuery)
