const alert = document.getElementById('alert__wrapper')
// console.log(alert)
if (alert) {
    const close = document.getElementsByClassName('alert__close')[0]
    const nav = document.getElementsByClassName('nav')[0]
    const main = document.getElementsByClassName('main-content')[0]

    close.addEventListener('click', () => {
        alert.classList.add('hide')
        nav.classList.remove('alert')
        main.classList.remove('alert')

        // adding cookie to expire in 1day
        const now = new Date()
        now.setTime(now.getTime() + 1 * 24 * 3600 * 1000)
        document.cookie = 'information=global-website-information; expires='
            + now.toUTCString() + '; path=/'
    })
}
