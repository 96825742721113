(($) => {
    const sections = document.getElementsByClassName('homepage__section')

    if (!sections.length) {
        return
    }

    const height = window.innerHeight

    const infographicGroup = document.getElementsByClassName('infographic__group')
    const glanceGroup = document.getElementsByClassName('glance__group')
    const todayGroup = document.getElementsByClassName('today__item')
    const eventsGroup = document.getElementsByClassName('upcoming-events__item homepage')

    const nav = document.getElementsByClassName('nav')[0]

    // add animations for each section appearing
    window.addEventListener('scroll', () => {
        Array.from(sections).map((section, key) => {
            var pTop

            if ($(section).hasClass('people-module__wrapper')) {
                pTop = section.getBoundingClientRect().top - 175
            }
            else {
                pTop = section.getBoundingClientRect().top
            }

            const pageTop = pTop

            if (pageTop < height / 4 * 3 + 80) {
                if (!section.classList.contains('active')) {
                    section.classList.add('active')
                    switch (key) {
                        // infographic section
                    case 1:
                        for (let i = 0; i < infographicGroup.length; i++) {
                            setTimeout(function () {
                                infographicGroup[i].classList.add('active')
                            }, 100 * i)
                        }

                        break
                        // at a glance
                    case 2:
                        for (let i = 0; i < glanceGroup.length; i++) {
                            setTimeout(function () {
                                glanceGroup[i].classList.add('active')
                            }, 100 * i)
                        }

                        break
                        // top moments
                    case 3:
                        for (let i = 0; i < todayGroup.length; i++) {
                            setTimeout(function () {
                                todayGroup[i].classList.add('active')
                            }, 100 * i)
                        }

                        break
                        // upcoming events
                    case 4:
                        for (let i = 0; i < eventsGroup.length; i++) {
                            setTimeout(function () {
                                eventsGroup[i].classList.add('active')
                            }, 100 * i)
                        }

                        break
                        // homepage quote
                    case 5:
                        break
                    }
                }
            }
        })

        // shrink nav height with scroll if on Andover site
        if (Boolean(nav.dataset.collapsible) && sections.length > 1) {
            if (sections[0].getBoundingClientRect().top * 3 + height > 0) {
                return nav.classList.contains('content')
                    ? nav.classList.remove('content')
                    : ''
            }
            else {
                return nav.classList.contains('content')
                    ? ''
                    : nav.classList.add('content')
            }
        }
    })
})(window.jQuery)
