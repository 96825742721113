(($) => {
    const close = document.getElementsByClassName('nav-content__close')
    const navMain = document.getElementsByClassName('nav-content__wrapper')[0]
    const open = document.getElementsByClassName('nav__menu')
    const links = document.getElementsByClassName('nav-content__left-link')
    const images = document.getElementsByClassName('nav-content__background')
    const searchIcon = document.getElementsByClassName('nav__icon-search')
    const searchBar = document.getElementsByClassName('nav__search')
    const navRight = document.getElementsByClassName('nav__right')
    const inputBar = document.getElementsByClassName('nav__search-input')
    const navLogo = document.getElementsByClassName('nav__logo')
    const navSecondary = document.getElementsByClassName('nav-secondary__title')
    const navSecMenu = document.getElementsByClassName('nav-secondary__nav')
    const mainContent = document.getElementsByClassName('main-content')
    const navBar = document.getElementsByClassName('nav')[0]

    // require('smoothscroll-polyfill').polyfill()

    // // import smoothscroll from 'smoothscroll-polyfill'
    // //smoothscroll.polyfill();

    if (close.length > 0) {
        close[0].addEventListener('click', () => {
            navMain.classList.remove('active')
            open[0].classList.remove('active')
            open[0].setAttribute('aria-expanded', 'false')
            mainContent[0].setAttribute('aria-hidden', 'false')
            if (window.innerWidth < 640) {
                navBar.classList.remove('active')
            }
        })

        close[0].addEventListener('keypress', (e) => {
            if (e.charCode === 13) {
                navMain.classList.remove('active')
                open[0].classList.remove('active')
                open[0].setAttribute('aria-expanded', 'false')
                mainContent[0].setAttribute('aria-hidden', 'false')
                if (window.innerWidth < 640) {
                    navBar.classList.remove('active')
                }
            }
        })
    }

    if (open.length > 0) {
        open[0].addEventListener('click', () => {
            if (navMain.classList.contains('active')) {
                navMain.classList.remove('active')
                open[0].classList.remove('active')
                open[0].setAttribute('aria-expanded', 'false')
                mainContent[0].setAttribute('aria-hidden', 'false')
                if (window.innerWidth <= 640) {
                    open[0].getElementsByClassName('nav__menu-text')[0].innerHTML = '&nbsp;&nbsp;Menu'
                }
                if (window.innerWidth < 640) {
                    navBar.classList.remove('active')
                }
            }
            else {
                navMain.classList.add('active')
                open[0].classList.add('active')
                open[0].setAttribute('aria-expanded', 'true')
                mainContent[0].setAttribute('aria-hidden', 'true')
                if (window.innerWidth > 640) {
                    navMain.scroll({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    })
                }
                else {
                    navMain.scrollTo(0, -1000)
                    open[0].getElementsByClassName('nav__menu-text')[0].innerHTML = '&nbsp;&nbsp;Close'
                }
                if (window.innerWidth < 640) {
                    navBar.classList.add('active')
                }
            }
        })

        open[0].addEventListener('keypress', (e) => {
            if (e.charCode === 13) {
                if (navMain.classList.contains('active')) {
                    navMain.classList.remove('active')
                    open[0].classList.remove('active')
                    open[0].setAttribute('aria-expanded', 'false')
                    mainContent[0].setAttribute('aria-hidden', 'false')
                    if (window.innerWidth <= 640) {
                        open[0].getElementsByClassName('nav__menu-text')[0].innerHTML = '&nbsp;&nbsp;Menu'
                    }
                    if (window.innerWidth < 640) {
                        navBar.classList.add('active')
                    }
                }
                else {
                    navMain.classList.add('active')
                    open[0].classList.add('active')
                    open[0].setAttribute('aria-expanded', 'true')
                    mainContent[0].setAttribute('aria-hidden', 'true')
                    if (window.innerWidth > 640) {
                        navMain.scroll({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                        })
                    }
                    else {
                        navMain.scrollTo(0, -1000)
                        open[0].getElementsByClassName('nav__menu-text')[0].innerHTML = '&nbsp;&nbsp;Close'
                    }
                    if (window.innerWidth < 640) {
                        navBar.classList.add('active')
                    }
                }
            }
        })
    }

    if (links.length > 1) {
        const imageNodes = Array.from(images)
        Array.from(links).map((link, key) => {
            link.addEventListener('mouseenter', () => {
                imageNodes.map(i => {
                    i.classList.remove('active')
                })
                imageNodes[key].classList.add('active')
            })
        })
    }

    if (searchIcon.length > 0) {
        searchIcon[0].addEventListener('click', () => {
            if (searchBar[0].classList.contains('active')) {
                searchBar[0].classList.remove('active')
                navLogo[0].classList.remove('active')
                navRight[0].classList.remove('active')
                searchIcon[0].setAttribute('aria-expanded', 'false')
            }
            else {
                searchBar[0].classList.add('active')
                navRight[0].classList.add('active')
                navLogo[0].classList.add('active')
                searchIcon[0].setAttribute('aria-expanded', 'true')
                inputBar[0].focus()
            }
        })

        searchIcon[0].addEventListener('keypress', (e) => {
            if (e.charCode === 13) {
                if (searchBar[0].classList.contains('active')) {
                    searchBar[0].classList.remove('active')
                    navLogo[0].classList.remove('active')
                    navRight[0].classList.remove('active')
                    searchIcon[0].setAttribute('aria-expanded', 'false')
                }
                else {
                    searchBar[0].classList.add('active')
                    navRight[0].classList.add('active')
                    navLogo[0].classList.add('active')
                    searchIcon[0].setAttribute('aria-expanded', 'true')
                    inputBar[0].focus()
                }
            }
        })
    }

    if (navSecondary.length > 0) {
        $.each(navSecondary, function (i) {
            const arrow = document.getElementsByClassName('nav-secondary__arrow')[i]
            if ($(navSecMenu[i]).hasClass('nav-secondary__nav-parent')) {
                if (window.innerWidth <= 640) {
                    navSecMenu[i].setAttribute('aria-hidden', 'true')
                }
            }
            else {
                navSecMenu[i].setAttribute('aria-hidden', 'true')
            }

            const toggleSecNav = () => {
                if (navSecMenu[i].classList.contains('active')) {
                    navSecMenu[i].classList.remove('active')
                    arrow.setAttribute('aria-expanded', 'false')
                    navSecMenu[i].setAttribute('aria-hidden', 'true')
                }
                else {
                    navSecMenu[i].classList.add('active')
                    arrow.setAttribute('aria-expanded', 'true')
                    navSecMenu[i].setAttribute('aria-hidden', 'false')
                }
            }

            arrow.addEventListener('click', () => {
                toggleSecNav()
            })

            arrow.addEventListener('keypress', (e) => {
                if (e.charCode === 13) {
                    toggleSecNav()
                }
            })

            window.addEventListener('touchend', (event) => {
                if (event.target === arrow) {
                    event.preventDefault()
                    toggleSecNav()
                }
            })
        })
    }

    const navTertiaryAll = document.getElementsByClassName('nav-tertiary__link')
    const navTertMenuAll = document.getElementsByClassName('nav-tertiary__nav')

    if (navTertiaryAll.length > 0) {
        $.each(navTertiaryAll, function (i) {
            const navTertMenu = $(this).children('.nav-tertiary__nav')
            const arrow = $(this).children('.nav-tertiary__arrow')

            if (navTertMenu.length > 0) {
                $(navTertMenu).attr('aria-hidden', 'true')
                $(navTertMenu).attr('aria-hidden', 'true')
                $(navTertMenu).css('visibility', 'hidden')
                $(navTertMenu).css('padding', '0')
                $(navTertMenu).children('li').hide()
                $(this).css('margin-bottom', '0 !important')

                const toggleTertNav = () => {
                    if ($(navTertMenu).hasClass('active')) {
                        $(navTertMenu).css('padding', '0')
                        $(navTertMenu).removeClass('active')
                        $(arrow).attr('aria-expanded', 'false')
                        $(arrow).removeClass('active')
                        $(navTertMenu).attr('aria-hidden', 'true')
                        $(navTertMenu).css('z-index', '0')
                        $(this).css('z-index', '0')
                        $(navTertMenu).css('visibility', 'hidden')
                        $(navTertMenu).children('li').hide()

                        if (window.innerWidth <= 640) {
                            $(navTertiaryAll).children('a').css('margin-bottom', '0')
                            $(navTertiaryAll).css('margin-bottom', '0.5rem')
                        }
                        else {
                            $(navTertiaryAll).css('margin-bottom', '0rem')
                        }

                        $(navTertMenuAll).css('margin-bottom', '0')
                    }
                    else {
                        $(navTertMenuAll).removeClass('active')
                        $('.nav-tertiary__arrow').attr('aria-expanded', 'false')
                        $('.nav-tertiary__arrow').removeClass('active')
                        $(navTertMenuAll).attr('aria-hidden', 'true')
                        $(navTertiaryAll).css('z-index', '0')
                        $(navTertMenuAll).css('visibility', 'hidden')
                        $(navTertMenuAll).css('padding-top', '0')
                        $(navTertMenuAll).children('li').hide()

                        if (window.innerWidth > 640) {
                            $(navTertMenu).css('padding', '0.5rem')
                            $(navTertMenu).css('paddingTop', '6rem')
                        }
                        else {
                            $(navTertMenu).css('paddingTop', '2rem')
                            $(navTertMenu).css('margin-bottom', '-0.5rem')
                            $(this).children('a').css('margin-bottom', '1rem')
                        }

                        $(navTertMenu).css('visibility', 'visible')
                        $(navTertMenu).addClass('active')
                        $(arrow).attr('aria-expanded', 'true')
                        $(arrow).addClass('active')
                        $(navTertMenu).attr('aria-hidden', 'false')
                        $(navTertMenu).css('z-index', '-1')
                        $(this).css('z-index', '10')
                        $(navTertMenu).children('li').show()
                    }
                }

                $(arrow).click(function () {
                    toggleTertNav()
                    console.log('clicked')
                })

                $(arrow).keypress((e) => {
                    if (e.charCode === 13) {
                        toggleTertNav()
                    }
                })

                window.addEventListener('touchend', (event) => {
                    if (event.target === arrow) {
                        event.preventDefault()
                        toggleTertNav()
                    }
                })
            }
        })
    }
})(window.jQuery)
