const eggList = document.getElementsByClassName('egg__wrapper')
const bodyList = document.getElementsByClassName('egg-body__wrapper')

if (eggList.length > 0) {
    const eggs = Array.from(eggList)
    const bodies = Array.from(bodyList)

    // for desktop
    window.addEventListener('click', (event) => {
        eggs.map((egg, key) => {
            if (egg.contains(event.target)) {
                event.preventDefault()
                bodies[key].classList.contains('active')
                    ? bodies[key].classList.remove('active')
                    : bodies[key].classList.add('active')
                bodies[key].classList.contains('active')
                    ? eggs[key].setAttribute('aria-expanded', 'true')
                    : eggs[key].setAttribute('aria-expanded', 'false')
            }
            else if (!bodies[key].contains(event.target)) {
                bodies[key].classList.remove('active')
                eggs[key].setAttribute('aria-expanded', 'false')
            }
        })
    })

    // for touch screens
    window.addEventListener('touchend', (event) => {
        eggs.map((egg, key) => {
            if (egg.contains(event.target)) {
                event.preventDefault()
                bodies[key].classList.contains('active')
                    ? bodies[key].classList.remove('active')
                    : bodies[key].classList.add('active')
                bodies[key].classList.contains('active')
                    ? eggs[key].setAttribute('aria-expanded', 'true')
                    : eggs[key].setAttribute('aria-expanded', 'false')
            }
            else if (!bodies[key].contains(event.target)) {
                bodies[key].classList.remove('active')
                eggs[key].setAttribute('aria-expanded', 'false')
            }
        })
    })

    // for keyboard users, press ENTER
    eggs.map((egg, key) => {
        egg.addEventListener('keypress', (event) => {
            if (event.charCode === 13) {
                event.preventDefault()
                bodies[key].classList.contains('active')
                    ? bodies[key].classList.remove('active')
                    : bodies[key].classList.add('active')
                bodies[key].classList.contains('active')
                    ? eggs[key].setAttribute('aria-expanded', 'true')
                    : eggs[key].setAttribute('aria-expanded', 'false')
            }
            else if (!bodies[key].contains(event.target)) {
                bodies[key].classList.remove('active')
                eggs[key].setAttribute('aria-expanded', 'false')
            }
        })
    })
}
